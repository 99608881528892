import React from 'react'
import { Row, Col, Container } from '../UI/Grid'
import { Section } from '../UI/Section'
import { Paragraph } from '../UI/Typography'
import ScaledImage from '../UI/ScaledImage'
import { Title } from '../Shared/SectionHeader.styles'
import { Card } from '../UI/Card'
import DefaultVector from '../Shared/DefaultVector'

export const Years = () => {
  return (
    <Section py={{ _: 10, lg: 0 }}>
      <DefaultVector>
        <Container mb={{ lg: 10 }}>
          <Row rowGap={10} justifyContent="center">
            <Col col={{ md: 8 }}>
              <Title size="h1" textAlign="center">
                Years of Leading-Edge Operations
                <br />
                Decades of Expertise and Experience
              </Title>
            </Col>
            <Col col={{ lg: 6 }}>
              <Paragraph as="p">
                Fastech is a leading Philippine semiconductor manufacturing company that has grown
                over the years by focusing on its passion for manufacturing excellence. The company
                established itself as a reliable partner and subcontractor for customers from the
                United States, Europe, and Asia.
                <br />
                <br />
                Fastech is recognized for its leading-edge knowhow in niche power semiconductor
                components (with expertise in using Silicon Carbide, and Gallium Nitride) and
                RF/Microwave modules.
              </Paragraph>
            </Col>
            <Col col={{ lg: 6 }}>
              <Card cardhidden flat>
                <ScaledImage scaleWidth={802} scaleHeight={449}>
                  <iframe
                    width="560"
                    height="315"
                    loading="lazy"
                    src="https://www.youtube.com/embed/GTJiM8inYYI"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </ScaledImage>
              </Card>
            </Col>
          </Row>
        </Container>
      </DefaultVector>
    </Section>
  )
}
