import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import MilestoneCard from '../Cards/MilestoneCard'
import SectionHeader from '../Shared/SectionHeader'
import { Row, Col, Container } from '../UI/Grid'
import ScaledImage from '../UI/ScaledImage'
import { Section } from '../UI/Section'
import Element from '../UI/Element'
import { graphql, useStaticQuery } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'
import ChevronLeftIcon from '../Icons/ChevronLeftIcon'
import ChevronRightIcon from '../Icons/ChevronRightIcon'
import theme from '../../utils/theme'
import styled from 'styled-components'

const HistoricalMilestone = () => {
  let milestone = []

  const allMilestone = useStaticQuery(graphql`
    query allMilestoneQuery {
      allMilestoneJson {
        nodes {
          id
          year
          description
          thumbnail {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP], layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)

  milestone = allMilestone.allMilestoneJson.nodes

  return (
    <Section bg="gray">
      <ScaledImage bg={true}>
        <StaticImage src="../../images/bg-historical.jpg" alt="bg" layout="fullWidth" />
      </ScaledImage>
      <Container>
        <Row flexWrap={{ lg: 'noWrap' }} rowGap={6}>
          <Col col={{ lg: 5 }}>
            <SectionHeader
              title="Historical Milestone"
              description="Started as Circuit Packaging Inc., and was later renamed Fastech, the company experienced rapid growth in its early years. It acquired companies and facilities that helped expand its production expertise and manufacturing capacity. From then on, Fastech continued its commitment to excellence. This enabled them to further grow the company and acquire awards and certifications over the years."
            />

            <Row gutterSize={0}>
              <Col col="auto">
                <Control size="display-4" color="primary" className="prev">
                  <ChevronLeftIcon />
                </Control>
              </Col>
              <Col col="auto">
                <Control size="display-4" color="primary" className="next">
                  <ChevronRightIcon />
                </Control>
              </Col>
            </Row>
          </Col>
          <ColSlider col={{ lg: 'fill' }}>
            <Swiper
              spaceBetween={16}
              slidesPerView={1.2}
              // slidersPerView={2.8}
              slideToClickedSlide={false}
              speed={1000}
              modules={[Navigation]}
              navigation={{
                nextEl: '.next',
                prevEl: '.prev',
              }}
              breakpoints={{
                556: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 2.5,
                },
                1200: {
                  slidesPerView: 1.5,
                },
                1400: {
                  slidesPerView: 2.2,
                },
                1800: {
                  slidesPerView: 2.8,
                },
              }}
            >
              {milestone.map(m => (
                <SwiperSlide key={m.id}>
                  <Element pb={2} height="100%">
                    <MilestoneCard
                      year={m.year}
                      description={m.description}
                      thumbnail={m.thumbnail}
                    />
                  </Element>
                </SwiperSlide>
              ))}
            </Swiper>
          </ColSlider>
        </Row>
      </Container>
    </Section>
  )
}

const ColSlider = styled(Col)`
  ${theme.media.up('lg')} {
    width: calc(((100vw - 1140px) * 0.5) + 1140px - 475px);
    max-width: none;
  }
  ${theme.media.up('xl')} {
    width: calc(((100vw - 1320px) * 0.5) + 1320px - 545px);
  }
`

const Control = styled(Element)`
  cursor: pointer;
`

export default HistoricalMilestone
