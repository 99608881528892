import React from "react";
import Icon from "../UI/Icon";

const ChevronLeftIcon = () => {
  return (
    <Icon viewBox="0 0 40 40">
      <path d="M25.683 12.35L23.333 10L13.333 20L23.333 30L25.683 27.65L18.0497 20L25.683 12.35Z" />
    </Icon>
  );
};

export default ChevronLeftIcon;
