import React from 'react'
import Seo from '../components/Layout/Seo'
import Site from '../components/Layout/Site'
import HistoricalMilestone from '../components/OurCompany/HistoricalMilestone'
import OurCompanyBanner from '../components/OurCompany/OurCompanyBanner'
import PillarsOfCorporate from '../components/OurCompany/PillarsOfCorporate'
import TheTeam from '../components/OurCompany/TheTeam'
import VisionMission from '../components/OurCompany/VisionMission'
import { Years } from '../components/OurCompany/Years'
import 'swiper/css'
import '../stylesheets/swiper-override.css'

const OurCompany = () => {
  return (
    <Site>
      <Seo title="Our Company" />
      <OurCompanyBanner />
      <Years />
      <VisionMission />
      <PillarsOfCorporate />
      <HistoricalMilestone />
      <TheTeam />
    </Site>
  )
}

export default OurCompany
