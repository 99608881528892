import React from "react";
import styled from "styled-components";
import { Row, Container, Col } from "../UI/Grid";
import { Section } from "../UI/Section";
import { handleResponsiveSize } from "../UI/Typography";
import Element from "../UI/Element";
import theme from "../../utils/theme";
import { StaticImage } from "gatsby-plugin-image";
import { Title } from "../Shared/SectionHeader.styles";
import CheckCircleIcon from "../Icons/CheckCircleIcon";
import DefaultVector from "../Shared/DefaultVector";
import Facade from "../../images/bg-facade.jpg";

const PillarsOfCorporate = () => {
  return (
    <SectionBg py={{ _: 16, lg: 0 }} defaultVector>
      <DefaultVector noBottom>
        <Container pb="180px">
          <Row>
            <Col col={{ sm: 6, md: 4, lg: 4 }}>
              <Element
                borderRadius="40px"
                py={9}
                px={4}
                backgroundColor="gray"
                mb={10}
              >
                <Title mb={10}>Pillars of Corporate Values</Title>
                <Row mb={{ _: 6, lg: 0 }} mt={4}>
                  <Col col="auto">
                    <StaticImage
                      src="../../images/planning.png"
                      alt="vector"
                      height={50}
                    />
                  </Col>
                  <Col col="fill">
                    <PillarTitle>Planning</PillarTitle>
                    <PillarDescription>
                      We are always ready regardless of circumstances. We
                      anticipate challenges and opportunities to achieve our
                      desired result.
                    </PillarDescription>
                  </Col>
                </Row>
                <Row mb={{ _: 6, lg: 0 }} mt={4}>
                  <Col col="auto">
                    <StaticImage
                      src="../../images/frugality.png"
                      alt="vector"
                      height={50}
                    />
                  </Col>
                  <Col col="fill">
                    <PillarTitle>Frugality </PillarTitle>
                    <PillarDescription>
                      We promote and contribute to the development of
                      opportunities, ideas and programs for sustainable
                      resources and cost savings. We avoid unnecessary
                      expenditure, either money or material resources. We
                      deliberately avoid waste.
                    </PillarDescription>
                  </Col>
                </Row>
                <Row mb={{ _: 6, lg: 0 }} mt={4}>
                  <Col col="auto">
                    <StaticImage
                      src="../../images/hardwork.png"
                      alt="vector"
                      height={50}
                    />
                  </Col>
                  <Col col="fill">
                    <PillarTitle>Hard Work</PillarTitle>
                    <PillarDescription>
                      We solve problems. We face challenges. We diligently work
                      to do the best way we know how. Hard work always works.
                    </PillarDescription>
                  </Col>
                </Row>
              </Element>
            </Col>
            <Col col={{ sm: 6, md: 4, lg: 4 }}>
              {" "}
              <Element
                borderRadius="40px"
                py={10}
                px={4}
                backgroundColor="#F8F9FC"
                mb={10}
              >
                <Title mb={10}>3 C's of Excellence</Title>
                <PillarEntry
                  title="Competence"
                  description="
                      We are physically and intellectually qualified to
                      contribute to the attainment of corporate goals."
                >
                  <StaticImage
                    src="../../images/competence.png"
                    alt="vector"
                    height={50}
                  />
                </PillarEntry>
                <PillarEntry
                  title="Commitment"
                  description="
                      We are intellectually and emotionally bonded to every
                      course of action that we take."
                >
                  <StaticImage
                    src="../../images/commitment.png"
                    alt="vector"
                    height={50}
                  />
                </PillarEntry>
                <PillarEntry
                  title="Consistency"
                  description="
                      We do the right things all the time."
                >
                  <StaticImage
                    src="../../images/consistency.png"
                    alt="vector"
                    height={50}
                  />
                </PillarEntry>
              </Element>
            </Col>
            <Col col={{ sm: 6, md: 4, lg: 4 }}>
              <Element borderRadius="40px" py={9} px={5} backgroundColor="gray">
                <Element mb={10}>
                  <Title mb={4}>7 Point Customer Commitment</Title>
                  <p>
                    To enable our customers/strategic partners to achieve a
                    sustainable advantage in terms of:
                  </p>
                </Element>
                <Row gutterSize={2}>
                  <Col col="auto">
                    <Element color="brand" fontSize="20px" lineHeight="1">
                      <CheckCircleIcon />
                    </Element>
                  </Col>
                  <Col col="fill">Quality</Col>
                </Row>
                <Row gutterSize={2} mt={1}>
                  <Col col="auto">
                    <Element color="brand" fontSize="20px" lineHeight="1">
                      <CheckCircleIcon />
                    </Element>
                  </Col>
                  <Col col="fill">Cost of Ownership</Col>
                </Row>
                <Row gutterSize={2} mt={1}>
                  <Col col="auto">
                    <Element color="brand" fontSize="20px" lineHeight="1">
                      <CheckCircleIcon />
                    </Element>
                  </Col>
                  <Col col="fill">Delivery (time to market/time to volume)</Col>
                </Row>
                <Row gutterSize={2} mt={{ _: 1, lg: 0 }}>
                  <Col col="auto">
                    <Element color="brand" fontSize="20px" lineHeight="1">
                      <CheckCircleIcon />
                    </Element>
                  </Col>
                  <Col col="fill">Process technology/engineering</Col>
                </Row>
                <Row gutterSize={2} mt={1}>
                  <Col col="auto">
                    <Element color="brand" fontSize="20px" lineHeight="1">
                      <CheckCircleIcon />
                    </Element>
                  </Col>
                  <Col col="fill">Assurance of supply (capacity planning)</Col>
                </Row>
                <Row gutterSize={2} mt={{ _: 1, lg: 0 }}>
                  <Col col="auto">
                    <Element color="brand" fontSize="20px" lineHeight="1">
                      <CheckCircleIcon />
                    </Element>
                  </Col>
                  <Col col="fill">Environmental Compliance</Col>
                </Row>
                <Row gutterSize={2} mt={1}>
                  <Col col="auto">
                    <Element color="brand" fontSize="20px" lineHeight="1">
                      <CheckCircleIcon />
                    </Element>
                  </Col>
                  <Col col="fill">Security (IP)</Col>
                </Row>
              </Element>
            </Col>
          </Row>
        </Container>
      </DefaultVector>
    </SectionBg>
  );
};

const SectionBg = styled(Section)`
  background-image: url(${Facade});
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: auto 200px;

  ${theme.media.up("md")} {
    background-size: auto 340px;
  }
`;

const PillarTitle = styled.div`
  font-weight: ${theme.fontWeights.bold};
  color: ${theme.colors.brand};
`;

const PillarDescription = styled.div`
  ${handleResponsiveSize("caption")};
`;

const PillarEntry = ({ title, description, children }) => {
  return (
    <Row mb={{ _: 6, lg: 0 }} mt={4}>
      <Col col="auto">{children}</Col>
      <Col col="fill">
        <PillarTitle>{title}</PillarTitle>
        <PillarDescription>{description}</PillarDescription>
      </Col>
    </Row>
  );
};

export default PillarsOfCorporate;
