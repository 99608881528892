import React from "react";
import SectionHeader from "../Shared/SectionHeader";
import { Row, Col, Container } from "../UI/Grid";
import { Section } from "../UI/Section";
import ScaledImage from "../UI/ScaledImage";
import { StaticImage } from "gatsby-plugin-image";
import { Paragraph } from "../UI/Typography";
import styled from "styled-components";
import ScaledElement from "../UI/ScaledElement";

const VisionMission = () => {
  return (
    <Section scaled py={0}>
      <ScaledElement scaleHeight={428} scaleWidth={1440} fullWidth>
        <ScaledImage bg={true}>
          <StaticImage
            src="../../images/bg-mission-vision.jpg"
            alt="bg"
            layout="fullWidth"
          />
        </ScaledImage>
        <Container color="white">
          <Row justifyContent="space-between">
            <Col col={{ lg: 5 }}>
              <SectionHeader title="Vision" inverse />
              <Paragraph mb={10} size="h4">
                Our vision is to rise to a position of leadership and excellence
                in providing world class electronic manufacturing services
              </Paragraph>
            </Col>
            <Col col={{ lg: 6 }}>
              <SectionHeader title="Mission" inverse />
              <List>
                <Paragraph as="li" mb={4}>
                  Achieve reasonable returns for our shareholders in a work
                  environment where employees are respected, listened,
                  recognized, involved and motivated
                </Paragraph>
                <Paragraph as="li" mb={4}>
                  Maintain long-term relationships of trust and confidence with
                  our clients
                </Paragraph>
                <Paragraph as="li">
                  Contribute to the sustainable development of the communities
                  where it operates
                </Paragraph>
              </List>
            </Col>
          </Row>
        </Container>
      </ScaledElement>
    </Section>
  );
};

const List = styled.ul`
  padding-left: 16px;
  margin: 0;
`;

export default VisionMission;
