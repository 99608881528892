import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import theme from "../../utils/theme";
import { Card } from "../UI/Card";
import { handleResponsiveSize } from "../UI/Typography";

const ManagementCard = ({ name, position, img }) => {
  return (
    <Card shadow cardhidden>
      <GatsbyImage image={getImage(img)} alt={name} />
      <Info>
        <Name>{name}</Name>
        <Position>{position}</Position>
      </Info>
    </Card>
  );
};

const Info = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 12px;
  color: #fff;
  line-height: 1.6;
  background: linear-gradient(
    360deg,
    #1a237e -0.68%,
    rgba(26, 35, 126, 0.54375) 55.29%,
    rgba(0, 14, 158, 0) 104.05%
  );
`;

const Name = styled.div`
  ${handleResponsiveSize("caption")};
  font-weight: ${theme.fontWeights.bold};
`;

const Position = styled.div`
  ${handleResponsiveSize("overline")};
  display: flex;
  min-height: 40px;
`;

export default ManagementCard;
