import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SectionHeader from '../Shared/SectionHeader'
import { Row, Col, Container } from '../UI/Grid'
import { Section } from '../UI/Section'
import ManagementCard from '../Cards/ManagementCard'
import { StaticImage } from 'gatsby-plugin-image'
import ScaledImage from '../UI/ScaledImage'

const TheTeam = () => {
  let members = []

  const allTeam = useStaticQuery(graphql`
    query allManagementQuery {
      allManagementJson {
        nodes {
          name
          position
          profile_pic {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP], layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `)

  members = allTeam.allManagementJson.nodes

  return (
    <Section pt={{ _: '64px', lg: '86px' }} pb={{ _: '64px', lg: '166px' }}>
      <ScaledImage bg objectFit="contain" objectPosition="right center">
        <StaticImage src="../../images/bg-team.png" alt="bg" width={900} layout="constrained" />
      </ScaledImage>
      <Container>
        <Row textAlign="center" justifyContent="center" mb={10}>
          <Col col={{ lg: 6 }}>
            <SectionHeader
              title="Management Team"
              description="To be the leader in Electronics outsource manufacturing"
            />
          </Col>
        </Row>

        <Row justifyContent="center">
          <Col col={{ lg: 10 }}>
            <Row
              rowGap={{ _: 4, md: 8 }}
              gutter={{ _: 2, md: 4 }}
              flexWrap={{ _: 'nowrap', md: 'wrap' }}
              style={{ overflowX: 'auto' }}
            >
              {members.map((m, idx) => (
                <Col col={{ _: 7, sm: 5, md: 4 }} key={idx}>
                  <ManagementCard name={m.name} position={m.position} img={m.profile_pic} />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}
export default TheTeam
