import styled from 'styled-components'
import { handleResponsiveSize } from '../UI/Typography'

export const DynamicContent = styled.div`
  * {
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', 'Noto Color Emoji' !important;
  }
  .text-semibold {
    font-weight: 600;
  }

  .text-bold {
    font-weight: 700;
  }

  .text-italic {
    font-style: italic;
  }

  ol {
    padding-left: 16px;
  }

  p + p {
    margin-top: 16px;
  }

  .card {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

    img {
      width: 100%;
    }
  }

  .text-center {
    text-align: center;
  }

  .card--footer {
    background: #1a237e;
    color: white;
    padding: 16px;
  }

  ${p => p.size && handleResponsiveSize(p.size)};
`
