import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { Card, CardContent } from '../UI/Card'
import Element from '../UI/Element'
import { Heading } from '../UI/Typography'
import ScaledImage from '../UI/ScaledImage'
import { Col, Row } from '../UI/Grid'
import { DynamicContent } from '../UI/DynamicContent'
import styled from 'styled-components'

const MilestoneCard = ({ year, description, thumbnail }) => {
  const [isReadMore, setIsReadMore] = useState(true)
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }

  return (
    <Card height="100%" minHeight={{ _: '425px', lg: '505px', xl: '490px' }}>
      <CardContent>
        <Element mb={4} textAlign="center">
          <ScaledImage scaleHeight={190} scaleWidth={265} objectFit="contain">
            <GatsbyImage image={getImage(thumbnail)} alt="description" />
          </ScaledImage>
        </Element>

        <Row gutterSize={2}>
          <Col col="auto">
            <Heading size="h3" mb={2} lineHeight="1.5">
              {year}:
            </Heading>
          </Col>
          <Col col="fill">
            <DynamicContent style={{ display: 'inline-block' }} size="h6">
              <Element
                display="inline"
                dangerouslySetInnerHTML={{
                  __html: isReadMore
                    ? `${description.slice(0, 92)}${description.length > 92 ? '...' : ''}`
                    : description,
                }}
              ></Element>
              {description.length > 92 && (
                <ReadMoreButton
                  as="button"
                  color="primary"
                  border={0}
                  bg="none"
                  p={0}
                  onClick={toggleReadMore}
                  className="read-or-hide"
                >
                  {isReadMore ? 'Read more' : ' Show less'}
                </ReadMoreButton>
              )}
            </DynamicContent>
          </Col>
        </Row>
      </CardContent>
    </Card>
  )
}

const ReadMoreButton = styled(Element)`
  background: none;
  margin-left: 2px;
  display: inline;
  text-decoration: underline;
`

export default MilestoneCard
