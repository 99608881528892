import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Banner from '../Shared/Banner'
import ScaledImage from '../UI/ScaledImage'

const OurCompanyBanner = () => {
  return (
    <Banner
      name="Who We are"
      description="The Leading Provider of<br/>Turnkey Manufacturing Services"
      col={9}
    >
      <ScaledImage bg={true}>
        <StaticImage
          src="../../images/banner-our-company.jpg"
          alt="banner"
          layout="fullWidth"
          quality="100"
        />
      </ScaledImage>
    </Banner>
  )
}

export default OurCompanyBanner
