import React from "react";
import Icon from "../UI/Icon";

const ChevronRightIcon = () => {
  return (
    <Icon viewBox="0 0 40 40">
      <path d="M16.6664 10L14.3164 12.35L21.9497 20L14.3164 27.65L16.6664 30L26.6664 20L16.6664 10Z" />
    </Icon>
  );
};

export default ChevronRightIcon;
